<template>
    <ErrorPage404 />
</template>

<script>
import { REDIRECT_HTTP_CODE } from '@configs/redirect';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { ASYNC_SEOMATIC_SERVICE_CONFIG } from '@async-services/seomatic/meta';

import { addDisableCacheHeader } from '@assets/headers';
import { getUrlForSeomatic, makeSeomaticRedirect } from '@assets/seomatic';

import { CORE_ERROR_DOMAIN } from '@errors/feature-domain-names';

import BasePage from '@pages/mixins/BasePage';

import Redirector from '@modules/redirector/Redirector';

import ErrorPage404 from '@organisms/ErrorPage/ErrorPage404';

export default {
    name: 'NotFound',

    components: {
        ErrorPage404,
    },

    mixins: [BasePage()],

    layout: () => 'empty',

    async asyncData({ app, store, route, route: { path: currentUrl }, redirect, req, res }) {
        if (process.server) {
            res.statusCode = 404;
            addDisableCacheHeader(res);
        }

        const urlForSeomatic = getUrlForSeomatic(req, route);
        const seomatic = await app.$asyncServices.use(
            ASYNC_SEOMATIC_SERVICE_CONFIG.NAME,
            ASYNC_SEOMATIC_SERVICE_CONFIG.METHODS.GET_SEO,
            {
                url: urlForSeomatic,
            }
        );

        if (seomatic?.redirect) {
            try {
                return makeSeomaticRedirect(seomatic.redirect, redirect, route);
            } catch (err) {
                app.$errorHandler.captureDomainError(
                    CORE_ERROR_DOMAIN,
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'makeSeomaticRedirect',
                    },
                    {
                        urlForSeomatic,
                        seomatic,
                    }
                );
            }
        }

        const locale = store.getters['config/locale'];

        const staticRedirects = Redirector.getRedirectsForLocale(locale);
        const staticRedirect = staticRedirects?.[decodeURI(currentUrl)];

        if (staticRedirect) {
            return redirect(REDIRECT_HTTP_CODE, staticRedirect);
        }
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            responseStatusCode: 404,
        };
    },
};
</script>
